<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">No. SPP</label>
        <vs-input class="w-full" :value="initData.header.no_spk" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Jenis SPP</label>
        <vs-input class="w-full" :value="initData.header.jenis_spk" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Proyek</label>
        <vs-input class="w-full" :value="initData.header.nama_proyek" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Rekanan</label>
        <vs-input class="w-full" :value="initData.header.nama_rekanan" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl SPP</label>
        <vs-input class="w-full" :value="initData.header.tgl_spk" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl Awal</label>
        <vs-input class="w-full" :value="initData.header.tgl_awal" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl Akhir</label>
        <vs-input class="w-full" :value="initData.header.tgl_akhir" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Masa (Hari)</label>
        <vs-input class="w-full" :value="initData.header.masa" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Jenis Kontrak*</label>
        <vs-input class="w-full" :value="initData.header.jenis_kontrak" readonly/>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Jenis Pekerjaan</label>
        <vs-input class="w-full" :value="initData.header.jenis_pekerjaan" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Lingkup Pekerjaan</label>
        <vs-input class="w-full" :value="initData.header.lingkup_pekerjaan" readonly/>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Files</label>
        <vs-button color="warning" v-if="initData.header.files_url.length > 0" type="border" size="small" icon-pack="feather" icon="icon-file" class="px-2" @click="showFiles(initData.header)">Lihat Files</vs-button>
        <div v-else class="text-sm">-</div>
      </div>
    </div>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>

    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>

  </div>
</template>

<script>
import FilesViewer from '@/views/components/files-viewer/FilesViewer'
export default {
  name: 'TabHeader',
  props: {
    initData: { default: null, type: Object }
  },
  components: {
    FilesViewer
  },
  data () {
    return {
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  },
  methods: {
    next () {
      const currentActiveTab = this.$store.state.approvals.spkKontrakApproval.activeTab
      this.$store.commit('approvals/spkKontrakApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    },
    showFiles (item) {
      this.modalFiles.filesUrl = item.files_url
      this.modalFiles.active = true
    }
  }
}
</script>
